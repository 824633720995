<template>
  <div class="msg">页面跳转中...</div>
</template>

<script>
export default {
  created(){
    const token = this.$route.query.t

    if(token){
      this.$store.commit("login/setLoginInfo", {
        access_token: token,
        refresh_token: ""
      })

      setTimeout(() => {
        window.location = window.origin + "/"
      },100)

      // this.$store.dispatch("user/userInfo").then(data => {
      //   
      // }).catch(err => {
      //   this.$router.replace({
      //     name: "UserHome"
      //   })
      // })
    }else{
      setTimeout(() => {
        window.location = window.origin + "/"
      },100)
    }
  }
}
</script>
<style lang="less">
  .msg{
    text-align: center;
    margin: 30px 0;
  }
</style>